<template>
    <div class="follow-detail">
        <!-- 操作记录的dialog -->
        <el-dialog
            v-if="info.type == 1"
            center
            width="480px"
            title="操作记录"
            :visible.sync="isShowDialog"
            :before-close="handleClose"
        >
            <el-tabs v-model="activeNamechange">
                <el-tab-pane label="流转记录" name="first">
                    <div v-if="info.operateLogs" class="timeblock">
                        <el-timeline>
                            <el-timeline-item
                                v-for="(item, index) in info.operateLogs"
                                :key="index"
                                type="primary"
                            >
                                <div class="flex">
                                    <div>{{ item.operatedTime | getYMDHMS }}</div>
                                    <div>({{ item.operator }})</div>
                                </div>
                                <h4 style="border-left: 6px solid #4086EC">
                                    <span />
                                    <!-- <p style="color: #4086ec">{{ item.modifyContent }}</p> -->
                                    <p>{{ item.content }}</p>
                                </h4>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                    <div v-else>
                        <span>无</span>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="通讯记录" name="second">
                    <div v-if="personClueData" class="timeblock">
                        <el-timeline>
                            <el-timeline-item
                                v-for="(item,index) in personClueData"
                                :key="index"
                                type="primary"
                            >
                                <div class="flex-center">
                                    <div>{{ item.createdTime|getYMDHMS }}</div>
                                    <div>({{ item.callerName }})</div>
                                </div>
                                <div class="audio-record">
                                    <div
                                        class="record-item"
                                    >{{ item.callerName }}与{{ item.calledName }}的通话</div>
                                    <div class="record-item">
                                        <callAudio :src="item.audioUrl" :font-size="'10px'" />
                                    </div>
                                </div>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                    <div v-else>
                        <span>无</span>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
        <!-- 历史记录的dialog -->
        <el-dialog
            v-if="info.type == 2"
            class="history-records"
            center
            width="649px"
            :visible.sync="isShowDialog"
            :before-close="handleClose"
        >
            <title-tap
                style="margin-top:20px"
                :active-name="activeName"
                :tab-data="tabData"
                @selectInfo="selectInfo"
            />
            <div v-if="activeName == 0" class="scroll-area">
                <!-- 跟进记录 -->
                <div v-if="client.followLogs.length" class="timeblock">
                    <el-timeline>
                        <el-timeline-item
                            v-for="(item, index) in client.followLogs"
                            :key="index"
                            type="primary"
                        >
                            <div class="flex">
                                <div>{{ item.followedTime | getYMDHMS }}</div>
                                <div>({{ item.follower }})</div>
                            </div>

                            <h4 style="border-left: 6px solid #4086EC">
                                <span />
                                <p style="color: #4086ec">{{ item.modifyContent }}</p>
                                <p>{{ item.followContent }}</p>
                            </h4>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <div v-else>
                    <img src="@/assets/common/no-data/no-records.svg" alt />
                    <div style="text-align:center;font-size: 12px;color: #AAAAAA">暂无跟进记录</div>
                </div>
            </div>
            <div v-if="activeName == 1" class="scroll-area">
                <div v-if="client.transferLogs.length > 0" class="timeblock">
                    <el-timeline>
                        <el-timeline-item
                            v-for="(item, index) in client.transferLogs"
                            :key="index"
                            type="primary"
                        >
                            <div class="flex">
                                <div>{{ item.operatedTime | getYMDHMS }}</div>
                                <div>({{ item.operator }})</div>
                            </div>

                            <h4 style="border-left: 6px solid #4086EC">{{ item.content }}</h4>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <div v-else>
                    <img src="@/assets/common/no-data/no-records.svg" alt />
                    <div style="text-align:center;font-size: 12px;color: #AAAAAA">暂无流转记录</div>
                </div>
            </div>
            <div v-if="activeName == 2" class="scroll-area">
                <div v-if="client.clueLogs.length > 0" class="timeblock">
                    <el-timeline>
                        <el-timeline-item
                            v-for="(item, index) in client.clueLogs"
                            :key="index"
                            type="primary"
                        >
                            <div class="flex">
                                <div>{{ item.createdTime | getYMDHMS }}</div>
                                <div>({{ item.source }})</div>
                            </div>

                            <h4>
                                <span />
                                <p>信息：{{ item.info }}</p>
                                <p style="display: flex">
                                    星级：{{ item.qualityLevel }}星
                                    <!-- <el-rate
                    v-model="item.qualityLevel"
                    disabled
                    text-color="#ff9900"
                                    >-->
                                    <!-- </el-rate> -->
                                </p>
                                <p>意向度：{{ item.purposeLevel }}</p>
                                <p>跟进结果：{{ item.status }}</p>
                            </h4>
                        </el-timeline-item>
                    </el-timeline>
                </div>
                <div v-else>
                    <img src="@/assets/common/no-data/no-records.svg" alt />
                    <div style="text-align:center;font-size: 12px;color: #AAAAAA">暂无线索记录</div>
                </div>
            </div>
            <div v-if="activeName == 3" class="scroll-area">
                <div class="timeblock">
                    <el-timeline>
                        <el-timeline-item
                            v-for="(item, index) in client.callLogs"
                            :key="index"
                            type="primary"
                        >
                            <div class="flex-center">
                                <div>{{ item.createdTime|getDate }}</div>
                                <div>({{ item.callerName }})</div>
                            </div>
                            <div class="audio-record">
                                <div
                                    class="record-item"
                                >{{ item.callerName }}与{{ item.calledName }}的通话</div>
                                <div class="record-item">
                                    <callAudio :src="item.audioUrl" :font-size="'10px'" />
                                </div>
                            </div>
                        </el-timeline-item>
                    </el-timeline>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import callAudio from '../../../components/callAudio.vue';
import { getYMDHMS } from '@/assets/js/time.js';
export default {
    components: {
        titleTap: () => import('@/components/templates/title-tap'),
        callAudio,
    },
    data() {
        return {
            isShowDialog: false,
            info: {},
            activeName: 0,
            tabData: ['跟进记录', '流转记录', '线索记录', '通讯记录'],
            client: {},
            activeNamechange: 'first',
        };
    },
    props: {
        personClueData: {
            type: Array,
            default: () => [],
        },
    },
    filters: {
        getDate(val) {
            console.log(val, 'das');
            return getYMDHMS(val);
        },
    },
    methods: {
        // 展示根据信息时间的弹框
        showDetail(info, type) {
            console.log(info);
            this.info = info;
            this.info.type = type;
            this.isShowDialog = true;
            if (info.client) {
                this.client = info.client;
                console.log(info.client, 'info.client');
                for (let i = 0; i < this.client.clueLogs.length; i++) {
                    let clueStatus = this.common.allValueNeed('clue-status');
                    let clueStatusVal = clueStatus.find((item) => {
                        return this.client.clueLogs[i].status == item.number;
                    });
                    if (clueStatusVal)
                        this.client.clueLogs[i].status = clueStatusVal.cnVal;
                    // 意向度-意向线索-等级
                    let loanPurposeLevel =
                        this.common.allValueNeed('loan-purpose-level');
                    let PurposeLevelVal = loanPurposeLevel.find((item) => {
                        return (
                            this.client.clueLogs[i].purposeLevel == item.number
                        );
                    });
                    if (PurposeLevelVal)
                        this.client.clueLogs[i].purposeLevel =
                            PurposeLevelVal.cnVal;
                    // 客户性别
                    let clientSex = this.common.allValueNeed('sex');
                    let clientSexVal = clientSex.find((item) => {
                        console.log(
                            'this.client.sex == item.number',
                            this.client.sex == item.number
                        );
                        return this.client.sex == item.number;
                    });
                    console.log('给爷爬', clientSexVal);
                    if (clientSexVal) this.client.sex = clientSexVal.cnVal;

                    // 客户线索跟进状态
                    let clientFollowStage = this.common.allValueNeed(
                        'client-follow-stage'
                    );
                    let loanPurposeLevelVal = clientFollowStage.find((item) => {
                        return this.client.followStage == item.number;
                    });
                    console.log(loanPurposeLevelVal);
                    if (loanPurposeLevelVal)
                        this.client.followStage = loanPurposeLevelVal.cnVal;
                    let vehiclePayType =
                        this.common.allValueNeed('vehicle-pay-type');
                    let vehicleVal = vehiclePayType.find((item) => {
                        return this.client.vehicle.paymentType == item.number;
                    });
                    if (vehicleVal)
                        this.client.vehicle.paymentType = vehicleVal.cnVal;

                    // house-pay-type
                    let housePayType =
                        this.common.allValueNeed('house-pay-type');
                    let houseVal = housePayType.find((item) => {
                        return this.client.house.paymentType == item.number;
                    });
                    if (houseVal)
                        this.client.house.paymentType = houseVal.cnVal;

                    // house-type
                    let houseType = this.common.allValueNeed('house-type');
                    let houseTypeVal = houseType.find((item) => {
                        return this.client.house.type == item.number;
                    });
                    if (houseTypeVal)
                        this.client.house.type = houseTypeVal.cnVal;
                    // gongjijin-pay-type
                    let gongjijinPayType =
                        this.common.allValueNeed('gongjijin-pay-type');
                    let gongjijinVal = gongjijinPayType.find((item) => {
                        return this.client.gongjijin.type == item.number;
                    });
                    if (gongjijinVal)
                        this.client.gongjijin.type = gongjijinVal.cnVal;
                    // shebao-type
                    let shebaoType = this.common.allValueNeed('shebao-type');
                    let shebaoTypeVal = shebaoType.find((item) => {
                        return this.client.shebao.type == item.number;
                    });
                    if (shebaoTypeVal)
                        this.client.shebao.type = shebaoTypeVal.cnVal;

                    console.log('this.client', this.client);
                }
                // for (let i = 0; i < this.client.followLog.length; i++) {
                //   this.client.followLog[i].followedTime = getYMDHMS(
                //     this.client.followLog[i].followedTime
                //   );
                // }
                // //数据转为可读或可用
                // for (let i = 0; i < this.client.transferLogs.length; i++) {
                //   this.client.transferLogs[i].operatedTime = getYMDHMS(
                //     this.client.transferLogs[i].operatedTime
                //   );
                // }
            }
        },
        // 弹框关闭触发
        handleClose() {
            this.isShowDialog = false;
        },
        selectInfo(val) {
            let index = val.index;
            this.activeName = index;
        },
    },
};
</script>

<style lang="less" scoped>
@import '@/css/table.less';

.follow-detail {
    /deep/ .el-dialog {
        display: flex;
        flex-direction: column;
        margin: 0 !important;
        position: absolute;
        height: 398px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: calc(100% - 30px);
        max-width: calc(100% - 30px);

        .el-dialog__body {
            padding: 30px 25px;
            flex: 1;
            overflow: auto;
        }
    }
}

.flex {
    display: flex;
    align-items: center;
    height: 16px;
    font-size: 14px;
    color: #aaaaaa;
}

.history-records {
    /deep/ .el-dialog__header {
        padding: 0;
    }

    /deep/ .el-dialog__body {
        padding-top: 0px !important;
        padding-right: 35px !important;
        overflow: hidden;
    }

    /deep/ .el-tabs__active-bar {
        height: 5px;
    }

    /deep/ .el-tabs__nav-wrap::after {
        background-color: #fff;
    }

    // .el-dialog{
    //   height:398px !important;
    // }
}

.scroll-area {
    overflow-y: scroll;
    height: 80%;
}
.audio-record {
    margin-top: 10px;
    // margin: 5px 0 5px 0;
    color: #ffffff;
    width: 300px;
    height: 84px;
    background-color: #3f81e7;

    .record-item {
        margin-left: 10px;
        padding-top: 10px;
    }
}
.flex-center {
    display: flex;
    align-items: center;
    height: 16px;
    font-size: 14px;
    color: #aaaaaa;
}
</style>
